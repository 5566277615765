import { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { Text } from 'ui/components/text';
import { idGenerator } from '../../../utils/helpers';
import s from './styles.module.scss';

interface IToggle {
  checked: boolean;
  id?: string;
  className?: string;
  wrapperClassName?: string;
  containerClassName?: string;
  labelText?: string;
  inactiveLabel?: string;
  activeLabel?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  showInnerLabel?: boolean;
  horizontal?: boolean;
}

const Toggle = ({
  checked,
  id: idProp,
  className,
  containerClassName,
  labelText,
  inactiveLabel = 'Off',
  activeLabel = 'On',
  disabled,
  onChange,
  showInnerLabel = true,
  horizontal,
  ...props
}: IToggle) => {
  const [isChecked, setIsChecked] = useState(checked);
  const id = useMemo(() => idProp || idGenerator(), []);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label
      htmlFor={id}
      className={cx(s.container, disabled && s.containerDisabled, horizontal && s.containerRow, className)}
    >
      {labelText && <span className={s.containerLabel}>{labelText}</span>}

      <input
        {...props}
        type="checkbox"
        className={s.containerInput}
        id={id}
        checked={checked}
        onChange={() => {
          setIsChecked(!isChecked);
          if (typeof onChange !== 'undefined') onChange(isChecked);
        }}
        disabled={disabled}
      />

      <div className={cx(s.containerToggle, containerClassName)}>
        {showInnerLabel && !isChecked && (
          <Text color={Text.color.accent} size="m" className={s.containerToggleOffLabel}>
            {inactiveLabel}
          </Text>
        )}
        <div className={s.containerTogglePoint} />
        {showInnerLabel && isChecked && (
          <Text color={Text.color.simpleWhite} size="m" className={s.containerToggleOnLabel}>
            {activeLabel}
          </Text>
        )}
      </div>
    </label>
  );
};

const Large = (props: IToggle) => <Toggle containerClassName={s.containerToggleLarge} {...props} />;
const Small = (props: IToggle) => <Toggle containerClassName={s.containerToggleSmall} {...props} />;

Toggle.Large = Large;
Toggle.Small = Small;

export { Toggle };
