import React from 'react';
import cx from 'classnames';
import s from './styles.module.scss';

type OverlayProps = { children?: React.ReactNode; className?: string; transparent?: boolean };

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  ({ children, className, transparent = false }: OverlayProps, ref): JSX.Element => {
    return (
      <div
        ref={ref}
        className={cx(s.overlay, className)}
        style={{ backgroundColor: `rgba(0, 0, 0, ${transparent ? 0 : 0.2})` }}
      >
        {children}
      </div>
    );
  },
);

Overlay.displayName = 'Overlay';
