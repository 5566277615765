import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ExpandedState,
  getExpandedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TableOptions } from '@tanstack/table-core';
import type { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import { Button, Icon, SearchTextInput, Text } from 'ui';
import cx from 'classnames';
import React, { useState, Fragment, useEffect } from 'react';
import s from './styles.module.scss';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  className?: string;
  subRowComponent?: React.ReactElement;
  options?: Omit<TableOptions<T>, 'data' | 'columns' | 'getCoreRowModel'>;
  isPaginated?: boolean;
  isSearchable?: boolean;
  isSortable?: boolean;
  searchField?: string;
  initialSorting?: SortingState;
}

const pageSize = 10;
const emptyHeight = 525;

export const Table = <T extends object>({
  data,
  columns,
  className,
  subRowComponent: SubRow,
  options,
  isPaginated,
  isSearchable,
  isSortable,
  initialSorting,
  searchField = 'name',
}: ReactTableProps<T>) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
      ...(isSearchable && { columnFilters }),
      ...(isSortable && { sorting }),
      ...options?.state,
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    ...(isPaginated && { getPaginationRowModel: getPaginationRowModel() }),
    ...options,
  });

  useEffect(() => {
    if (isPaginated) table.setPageSize(pageSize);
  }, [isPaginated]);
  const filteringFlag = columnFilters.length > 0 && table.getFilteredRowModel().rows.length >= pageSize;
  const isShowPagination = (columnFilters.length === 0 && data?.length >= pageSize) || filteringFlag;

  return (
    <div className={s.wrapper}>
      {isSearchable && (
        <SearchTextInput
          className={s.search}
          onSearchClick={(value) => table.getColumn(searchField)?.setFilterValue(value)}
        />
      )}
      <div className={s.wrapperTable}>
        <table className={cx(s.table, className)}>
          <thead className={s.tableHead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={s.tableHeadCell}
                    style={{
                      width: header.getSize() !== 0 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={header.column.getCanSort() && isSortable ? s.cellWithSorting : ''}
                        onClick={header.column.getToggleSortingHandler()}
                        aria-hidden
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        {header.column.getCanSort() && isSortable && (
                          <div className={s.sortButtons}>
                            <Icon
                              icon={Icon.icons.boldArrowUp}
                              size="XXS"
                              className={
                                (header.column.getIsSorted() as string) === 'asc' ? s.sortIconActive : s.sortIcon
                              }
                            />
                            <Icon
                              icon={Icon.icons.boldArrowDown}
                              size="XXS"
                              className={
                                (header.column.getIsSorted() as string) === 'desc' ? s.sortIconActive : s.sortIcon
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={s.tableBody}>
            {/* TBD, should we show next block in any case or just for paginated table */}
            {!table.getFilteredRowModel().rows.length && (
              <div style={{ height: `${emptyHeight}px` }}>
                <div className={s.empty}>No records found by this filter</div>
              </div>
            )}
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr key={row.id} className={s.tableBodyRow}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={s.tableBodyCell}
                      key={cell.id}
                      style={{
                        width: cell.column.getSize() !== 0 ? cell.column.getSize() : undefined,
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() ? (
                  <tr>
                    <td colSpan={3}>{SubRow}</td>
                  </tr>
                ) : null}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {isPaginated && isShowPagination && (
        <div className={s.footer}>
          <Text>
            Page {Number(table.options.state.pagination?.pageIndex) + 1} of {table.getPageCount()}
          </Text>
          <div className={s.footerActions}>
            <Button
              buttonClassName={s.footerBtn}
              variant="outline"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              buttonClassName={s.footerBtn}
              variant="outline"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
