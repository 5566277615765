import cx from 'classnames';
import { Tooltip } from '../tooltip';
import { Loader } from '../preloader';
import s from './styles.module.scss';
import type { BaseButtonProps, TButtonProps } from './types';

export const ButtonTooltip = ({
  tooltipClass,
  text,
  placement,
  className,
  children,
  ...props
}: BaseButtonProps): JSX.Element => (
  <Tooltip textClassName={tooltipClass} text={text} placement={placement} className={className} {...props}>
    {children}
  </Tooltip>
);
export const Button = ({
  type = 'button',
  buttonClassName = '',
  children = null,
  disabled = false,
  variant = 'primary',
  isLoading,
  tooltipClass,
  ...props
}: TButtonProps): JSX.Element => {
  return (
    <ButtonTooltip tooltipClass={tooltipClass} {...props}>
      <button
        {...props}
        type={type}
        className={cx(
          s.button,
          s[variant],
          buttonClassName,
          disabled && s.buttonDisabled,
          isLoading && s.buttonLoading,
        )}
        disabled={disabled}
      >
        <div className={cx(s.buttonContent, isLoading && s.buttonContentInvisible)}>{children}</div>

        {isLoading && (
          <Loader.Small
            color={variant === 'primary' ? 'white' : undefined}
            loaderImageClassName={s.spinnerRoot}
            className={s.spinner}
          />
        )}
      </button>
    </ButtonTooltip>
  );
};
