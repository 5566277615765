import cx from 'classnames';
import { Icon, Text } from 'ui';
import { logsScannedFormat } from 'app/utils';
import { forwardRef } from 'react';
import { LogLineDto } from 'data';
import s from './styles.module.scss';

type LoadingProps = {
  logs: LogLineDto[];
  index?: number;
  text?: string;
  isBase?: boolean;
  isHide?: boolean;
  isDown?: boolean;
};

export const Loading = forwardRef<HTMLDivElement, LoadingProps>(
  ({ logs, index, text, isBase, isHide, isDown }, ref) => {
    const generateScannedUpToMsg = (i: number) => {
      return logs?.length > 0 ? `Scanned up to ${logsScannedFormat(new Date(logs[i - 1].timeStamp))}` : '';
    };

    if (isHide) return null;

    return (
      <div className={cx(s.loadingBlock, isDown && s.loadingBlockDown, isBase && s.loadingBase)} ref={ref}>
        <Icon icon={text ? Icon.icons.info : Icon.icons.processing} className={s.loadingIcon} />
        <Text
          size={Text.size.m}
          color={Text.color.fontPrimaryDisabled}
          className={cx(s.loadingText, isBase && s.loadingTextBase)}
        >
          {text ?? `Loading... ${generateScannedUpToMsg(index ?? 1)}`}
        </Text>
      </div>
    );
  },
);

Loading.displayName = 'Loading component';
