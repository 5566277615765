import { memo } from 'react';
import { SelectInput, TextInput, Text } from 'ui';
import cx from 'classnames';
import { memory } from 'app/constants/data';
import { handleCpuResourcesInput, handleIntegerInput } from 'app/utils/helpers';
import { Field } from '../ui/form/field';

import s from './styles.module.scss';

type ResourcesProps = {
  startName?: string;
  className?: string;
  wrapperClassName?: string;
};

const ResourcesComp = ({ startName, className, wrapperClassName }: ResourcesProps) => (
  <div className={cx(s.wrapper, wrapperClassName)}>
    <Text component="p" size="l" weight="bold" className={s.containerFormSubtitle}>
      Resources
    </Text>
    <div className={cx(s.containerBody, className)}>
      <Field
        className={s.containerFormField}
        component={TextInput}
        id="resources.replicas"
        name="resources.replicas"
        labelText="Replicas"
        onKeyPress={handleIntegerInput}
      />
      <Field
        className={s.containerFormField}
        component={TextInput}
        id={`${startName ? `${startName}.` : ''}resources.cpuRequested`}
        name={`${startName ? `${startName}.` : ''}resources.cpuRequested`}
        labelText="Requested CPU (#)"
        onKeyPress={handleCpuResourcesInput}
      />
      <Field
        className={s.containerFormField}
        component={TextInput}
        id={`${startName ? `${startName}.` : ''}resources.cpuLimit`}
        name={`${startName ? `${startName}.` : ''}resources.cpuLimit`}
        labelText="CPU limit (#)"
        onKeyPress={handleCpuResourcesInput}
      />
      <div />
      <div className={cx(s.containerFormField, s.fieldGroup)}>
        <Field
          className={s.fieldGroupMain}
          component={TextInput}
          id={`${startName ? `${startName}.` : ''}resources.memoryRequested.value`}
          name={`${startName ? `${startName}.` : ''}resources.memoryRequested.value`}
          labelText="Requested memory"
          onKeyPress={handleIntegerInput}
        />
        <Field
          className={s.fieldGroupSelect}
          options={memory}
          component={SelectInput}
          id={`${startName ? `${startName}.` : ''}resources.memoryRequested.unit`}
          name={`${startName ? `${startName}.` : ''}resources.memoryRequested.unit`}
          onChangeAdapter={(v) => v?.value}
          valueAdapter={(v) => memory?.find((el) => el.value === v) || ''}
          maxMenuHeight="200px"
        />
      </div>

      <div className={cx(s.containerFormField, s.fieldGroup)}>
        <Field
          className={s.fieldGroupMain}
          component={TextInput}
          id={`${startName ? `${startName}.` : ''}resources.memoryLimit.value`}
          name={`${startName ? `${startName}.` : ''}resources.memoryLimit.value`}
          labelText="Memory limit"
          onKeyPress={handleIntegerInput}
        />
        <Field
          className={s.fieldGroupSelect}
          options={memory}
          component={SelectInput}
          id={`${startName ? `${startName}.` : ''}resources.memoryLimit.unit`}
          name={`${startName ? `${startName}.` : ''}resources.memoryLimit.unit`}
          onChangeAdapter={(v) => v?.value}
          valueAdapter={(v) => memory?.find((el) => el.value === v) || ''}
          maxMenuHeight="200px"
        />
      </div>
    </div>
  </div>
);

export const Resources = memo(ResourcesComp);
