import { memo } from 'react';
import cx from 'classnames';
import { handleIntegerInput } from 'app/utils';
import { memory } from 'app/constants/data';
import { SelectInput, TextInput } from 'ui';
import { Field } from '../ui/form/field';
import s from './styles.module.scss';

export const BackupSizeComponent = () => (
  <div className={cx(s.containerFormField, s.fieldGroup)}>
    <Field
      className={s.fieldGroupMain}
      component={TextInput}
      id="storage.backupSize.value"
      name="storage.backupSize.value"
      labelText="Backup size"
      onKeyPress={handleIntegerInput}
    />
    <Field
      className={s.fieldGroupSelect}
      options={memory}
      component={SelectInput}
      id="storage.backupSize.unit"
      name="storage.backupSize.unit"
      onChangeAdapter={(v) => v?.value}
      valueAdapter={(v) => {
        return memory?.find((el) => el.value === v) || '';
      }}
      maxMenuHeight="200px"
      isMenuAbove
    />
  </div>
);

export const StorageBackupSize = memo(BackupSizeComponent);
