import { useRef, useState } from 'react';
import cx from 'classnames';
import { Icon } from '../../../icon';
import { IconButton } from '../../../button/icon-button';
import { TextInput, type ITextInput } from '..';
import s from './styles.module.scss';

export interface SearchTextInputProps extends ITextInput {
  onSearchClick: (value?: string) => void;
  onClear?: (value?: string) => void;
}

export const SearchTextInput = ({
  onClear,
  onSearchClick,
  className,
  disabled,
  placeholder = 'Search...',
  ...props
}: SearchTextInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const dontLoseFocusOnClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  return (
    <div className={cx(s.search, className)}>
      <div className={cx(s.searchWrapper)}>
        <TextInput
          ref={inputRef}
          className={s.searchWrapperInput}
          placeholder={placeholder}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSearchClick(String(inputValue));
            }
          }}
          disabled={disabled}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          {...props}
        />
        {inputValue && (
          <IconButton
            onClick={() => {
              // CLEAR inner value and add to callback value to outside
              dontLoseFocusOnClick();
              setInputValue('');
              if (onClear) onClear('');
            }}
            icon={Icon.icons.close}
            className={s.searchIconClose}
            disabled={disabled}
          />
        )}
        <IconButton
          disabled={disabled}
          onClick={() => {
            dontLoseFocusOnClick();
            onSearchClick(String(inputValue));
          }}
          icon={Icon.icons.search}
          buttonClassName={s.searchIconSearch}
        />
      </div>
    </div>
  );
};
