import { ReactNode } from 'react';
import { Link as OriginalLink } from 'react-router-dom';
import cx from 'classnames';
import s from './styles.module.scss';

interface ILink {
  className?: string;
  to: string;
  children: ReactNode;
}
export const Link = ({ className, children, ...props }: ILink): JSX.Element => (
  <span className={cx(s.link, className)}>
    <OriginalLink {...props}>{children}</OriginalLink>
  </span>
);
