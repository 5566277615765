import { LogLineDto } from 'data';
import { OptionLevelType, levels } from './levels';

export const initialState = {
  goingUp: false,
  referenceTimeStamp: '',
  expandedRows: [],
  filter: '',
  level: levels[0],
  prevLogs: false,
  logs: [],
  isLoading: false,
  errorMessage: null,
  valueForTriggerFetch: null,
};

export enum Constants {
  'GOING_UP' = 'GOING_UP',
  'GOING_DOWN' = 'GOING_DOWN',
  'SET_LEVEL' = 'SET_LEVEL',
  'SET_EXPANDED_ROWS' = 'SET_EXPANDED_ROWS',
  'SET_SEARCH' = 'SET_SEARCH',
  'SET_TIMESTAMP' = 'SET_TIMESTAMP',
  'SET_PREV_LOGS' = 'SET_PREV_LOGS',
  'SET_LOGS' = 'SET_LOGS',
  'SET_LOADING' = 'SET_LOADING',
  'SET_ERROR' = 'SET_ERROR',
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state: StateType = initialState, { type, payload }: ActionType) => {
  switch (type) {
    case Constants.GOING_UP:
      return { ...state, ...payload };
    case Constants.GOING_DOWN:
      return { ...state, ...payload };
    case Constants.SET_LEVEL:
      return { ...state, level: payload };
    case Constants.SET_EXPANDED_ROWS:
      return { ...state, expandedRows: payload };
    case Constants.SET_SEARCH:
      return { ...state, filter: payload };
    case Constants.SET_PREV_LOGS:
      return { ...state, prevLogs: payload };
    case Constants.SET_LOGS:
      return { ...state, logs: payload };
    case Constants.SET_TIMESTAMP:
      return { ...state, referenceTimeStamp: payload };
    case Constants.SET_LOADING:
      return { ...state, isLoading: payload };
    case Constants.SET_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return initialState;
  }
};

export type StateType = {
  goingUp: boolean;
  referenceTimeStamp: string;
  expandedRows: boolean[];
  filter: string;
  level: OptionLevelType;
  prevLogs: boolean;
  logs: LogLineDto[];
  isLoading: boolean;
  errorMessage: null | string;
  valueForTriggerFetch?: string | null;
};

type ActionGoingUp = {
  type: Constants.GOING_UP | Constants.GOING_DOWN;
  payload: {
    goingUp: boolean;
    referenceTimeStamp: string;
    prevLogs: boolean;
    valueForTriggerFetch?: string | null;
  };
};

type ActionLevel = {
  type: Constants.SET_LEVEL;
  payload: OptionLevelType;
};

type ActionRows = {
  type: Constants.SET_EXPANDED_ROWS;
  payload: boolean[];
};

type ActionSearch = {
  type: Constants.SET_SEARCH;
  payload: string;
};

type ActionStamp = {
  type: Constants.SET_TIMESTAMP;
  payload: string;
};

type ActionPrevLogs = {
  type: Constants.SET_PREV_LOGS;
  payload: boolean;
};

type ActionSetLogs = {
  type: Constants.SET_LOGS;
  payload: LogLineDto[];
};

type ActionLoading = {
  type: Constants.SET_LOADING;
  payload: boolean;
};

type ActonError = {
  type: Constants.SET_ERROR;
  payload: null | string;
};

export type ActionType =
  | ActionSetLogs
  | ActionGoingUp
  | ActionLevel
  | ActionRows
  | ActionSearch
  | ActionStamp
  | ActionPrevLogs
  | ActionLoading
  | ActonError;
