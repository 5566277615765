import { useMemo } from 'react';
import { ApplicationDto } from 'data';

const BASE_URL = window._env_?.CONFIG_BASEURL || import.meta.env.VITE_BASE_URL;

type Props = {
  domain?: string;
  name?: string;
  type?: ApplicationDto.type;
  validatorParent?: string;
};

export const usePublicApplicationUrl = ({ domain, name, type, validatorParent }: Props) => {
  const protocol = 'https://';
  const targets = BASE_URL.split(protocol);
  const domainUrl = targets.pop();

  return useMemo(() => {
    let res = protocol;
    const resDomain = domain ? `${domain}.` : '';
    const resUIAPP = name && validatorParent == null ? `/${name}` : '';
    const resBEAPP = name ? `/${name}/api` : '';

    if (type === ApplicationDto.type.UI) {
      res += `${resDomain}${domainUrl}${resUIAPP}`;
    } else {
      res += `${resDomain}${domainUrl}${resBEAPP}`;
    }

    if (resUIAPP || resBEAPP) return res;

    return '';
  }, [domain, name, type]);
};
