import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import cx from 'classnames';
import { PageSubCaption, Table } from 'app/components/core/ui';
import { NoEntityData } from 'app/components/core/empty-state';
import { dayTimeFormatFromUnix, generateSizeLabel } from 'app/utils';
import { type BackupHistoryDto, errorTransformer } from 'data';
import { Status, TableSkeleton, Text } from 'ui';
import { ErrorWireframe } from '../error-wireframe';
import s from './styles.module.scss';

type BackupsHistoryProps = {
  backupsHistory: BackupHistoryDto[] | undefined;
  isHistoryLoading: boolean;
  historyLoadingError: unknown;
};

const mapStatusToColor = {
  succeeded: Status.mode.success,
  running: Status.mode.success,
  pending: Status.mode.info,
  unknown: Status.mode.info,
  failed: Status.mode.error,
};

export const BackupsHistory = ({ backupsHistory, isHistoryLoading, historyLoadingError }: BackupsHistoryProps) => {
  const cols = useMemo<ColumnDef<BackupHistoryDto>[]>(
    () => [
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ getValue }) => {
          const status = getValue() as string;

          return <Status mode={mapStatusToColor[status.toLowerCase()]}>{status}</Status>;
        },
      },
      {
        header: 'File',
        accessorKey: 'filename',
      },
      {
        header: 'Size',
        accessorKey: 'size',
        cell: (row) => generateSizeLabel(row.renderValue() || 0),
        size: 25,
        enableSorting: false,
      },
      {
        header: 'Timestamp',
        accessorKey: 'timestamp',
        cell: ({ getValue }) => dayTimeFormatFromUnix(getValue() as string),
      },
    ],
    [],
  );
  const tableContent =
    backupsHistory && backupsHistory.length > 0 ? (
      <Table data={backupsHistory} columns={cols} isPaginated />
    ) : (
      <NoEntityData entityName="backups schedule" />
    );

  if (historyLoadingError) {
    return (
      <div className={cx(s.containerBody, s.history)}>
        <Text size="xl" weight="regular" className={s.historyHeader}>
          Backups history
        </Text>

        <ErrorWireframe message={errorTransformer(historyLoadingError)} />
      </div>
    );
  }

  return (
    <div className={cx(s.containerBody, s.history)}>
      <div className={s.containerHeader}>
        <Text size="xl" weight="regular" className={s.historyHeader}>
          Backups history
        </Text>

        <PageSubCaption isDataLoaded={!isHistoryLoading} text="item" data={backupsHistory} />
      </div>

      {isHistoryLoading && !backupsHistory?.length ? <TableSkeleton /> : tableContent}
    </div>
  );
};
