import { EventType, RightType, LevelType, LogLevel } from 'data/constants';
import { DomainDto, ParticipantDto } from './openapi';

export type WithoutNullableKeys<Type> = {
  [Key in keyof Type]-?: WithoutNullableKeys<NonNullable<Type[Key]>>;
};

export type ErrorPayload = { error?: string; errorDescription?: string };

export type IError = {
  detail: string;
  status?: number;
  title?: string;
  type?: string;
  response: {
    data?: ErrorPayload;
    statusText?: string;
  };
};

export type Response<T = any> = ErrorPayload & {
  data: T;
  hasError: boolean;
};

export interface IEvent {
  phase: DomainDto.phase | ParticipantDto.phase | undefined;
  source: string;
  type: EventType;
}

export enum FileType {
  'DAR' = 'DAR',
  'UI_PACKAGE' = 'UI_PACKAGE',
}

export type RightDto = {
  type: RightType;
  party?: string;
};

export type UserDto = {
  userId: string;
  primaryParty?: string;
  rights?: RightDto[];
};

export type PartyDto = {
  displayName: string;
  identifier: string;
  isLocal: boolean;
};

export type LogsType = {
  timestamp: string;
  content: string;
  level: string;
};

export type LogsFilters = {
  numLogs: number;
  referenceTimeStamp: string;
  prevLogs: boolean;
  filter: string;
  level: LevelType;
};

type OptionLogLevelType = {
  value: LogLevel;
  label: LogLevel;
};

export const LogLevels: OptionLogLevelType[] = Object.values(LogLevel).map((l) => ({
  value: l,
  label: l,
}));
