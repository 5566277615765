import cx from 'classnames';
import { DropdownIndicatorProps, components as selectComponents } from 'react-select';
import { Icon } from 'ui/components/icon';
import { TOption } from '../types';
import s from './styles.module.scss';

const CustomDropDownIndicator = <IsMulti extends boolean = false>(
  indicatorProps: DropdownIndicatorProps<TOption, IsMulti>,
): JSX.Element => {
  const { selectProps } = indicatorProps;
  return (
    <selectComponents.DropdownIndicator {...indicatorProps}>
      <Icon icon={Icon.icons[selectProps.menuIsOpen ? 'arrowUp' : 'arrowDown']} className={cx(s.arrow)} />
    </selectComponents.DropdownIndicator>
  );
};

export default CustomDropDownIndicator;
