import { ReactNode } from 'react';
import cx from 'classnames';
import { Icon } from '../icon';
import { Text } from '../text';
import { modes, statusServerConverter, TMode, TModes } from './modes';
import s from './styles.module.scss';

interface IStatusProps {
  mode: TMode;
  className?: string;
  children: ReactNode;
  onClick?: () => void | null;
}

const statusModes = Object.entries(modes).reduce((obj: TModes, [modeName, mode]) => {
  const result = obj;
  result[modeName] = {
    type: s[`statusMode__${modeName}`],
    icon: mode.icon,
    color: mode.color,
  };
  return result;
}, {});

export const Status = ({
  mode = {
    type: 'unknown',
    icon: '',
    color: '',
  },
  className,
  children,
  ...props
}: IStatusProps): JSX.Element => (
  <div className={cx(s.status, mode.type, className)} {...props}>
    <Icon icon={Icon.icons[mode.icon]} className={cx(s.statusIcon, children && s.statusIconMargin)} />
    {children && (
      <Text size={Text.size.s} color={Text.color[mode.color]} weight={Text.weight.bold} className={s.statusText}>
        {children}
      </Text>
    )}
  </div>
);

Status.mode = statusModes;

export const correctStatus = (status: string | undefined) => {
  const transformedStatus = typeof status === 'string' ? status.toLowerCase() : statusServerConverter(status);
  if (!transformedStatus) {
    return { ...Status.mode.unknown };
  }
  if (
    transformedStatus === 'pending' ||
    transformedStatus === 'waiting' ||
    transformedStatus === 'in_progress' ||
    transformedStatus === 'compensating'
  ) {
    return { ...Status.mode.processing };
  }

  if (transformedStatus === 'deleted') {
    return { ...Status.mode.info };
  }
  if (transformedStatus === 'terminating' || transformedStatus === 'recovering' || transformedStatus === 'canceled') {
    return { ...Status.mode.warning };
  }
  if (transformedStatus === 'running' || transformedStatus === 'completed' || transformedStatus === 'created') {
    return { ...Status.mode.success };
  }
  if (transformedStatus === 'failed') {
    return { ...Status.mode.error };
  }
  return modes[transformedStatus] ? Status.mode[transformedStatus] : Status.mode.unknown;
};
