/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnvVar } from './EnvVar';
import type { ResourcesDto } from './ResourcesDto';
// for FE types validation
import { StorageDto } from './StorageDto';

export type ApplicationDto = {
  domain: string;
  envVars?: Array<EnvVar>;
  image?: string;
  name: string;
  packageName?: string;
  phase?: ApplicationDto.phase;
  port?: number;
  resources: ResourcesDto;
  type: ApplicationDto.type;
  // for FE types validation
  storage?: StorageDto;
  validatorParent?: string;
};

export namespace ApplicationDto {
  export enum phase {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
  }

  export enum type {
    BACKEND = 'BACKEND',
    UI = 'UI',
  }
}
