import React, { useEffect, useRef } from 'react';

const MOUSEUP = 'mouseup';
const TOUCHEND = 'touchend';

type HandledEvents = [typeof MOUSEUP, typeof TOUCHEND];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];
type Handler = (event?: PossibleEvent) => void;

const events: HandledEvents = [MOUSEUP, TOUCHEND];

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  handler: Handler | null,
  trigger?: HTMLElement | null | React.RefObject<HTMLElement>,
): void => {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    const listener = (event: PossibleEvent) => {
      // that need for prevent open-open behavior when click on profile btn
      if (trigger instanceof HTMLElement) {
        if (trigger === event.target || trigger.contains(event.target as Node)) return;
      } else if (
        trigger?.current &&
        (trigger.current === event.target || trigger.current?.contains(event.target as Node))
      ) {
        return;
      }

      if (!ref.current || !handlerRef.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handlerRef.current(event);
    };

    events.forEach((event) => {
      document.addEventListener(event, listener);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [handler]);
};
