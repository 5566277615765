import data from './data.json';

let { url } = data;
let { realm } = data;
let { clientId } = data;

if (process.env.NODE_ENV !== 'development') {
  url = `${window._env_.KEYCLOAK_URL}`;
  realm = window._env_.KEYCLOAK_REALM;
  clientId = window._env_.KEYCLOAK_CLIENT_ID;
}

export interface INameAuthProps {
  url: string;
  realm: string;
  clientId: string;
  AUTH_KEY: string;
}

export const auth: INameAuthProps = {
  url: `${url}/auth`,
  realm,
  clientId,
  AUTH_KEY: data.AUTH_KEY,
};
