export const enterpriseCronOptions = {
  useSeconds: true,
  useYears: true,
  useAliases: true,
  useBlankDay: true,
  allowOnlyOneBlankDayField: true,
  useLastDayOfMonth: true,
  useLastDayOfWeek: true,
  useNearestWeekday: true,
  useNthWeekdayOfMonth: true,
};

export const backupScheduleCronOptions = {
  useYears: false,
  useAliases: true,
  useBlankDay: true,
  allowOnlyOneBlankDayField: true,
  useLastDayOfMonth: true,
  useLastDayOfWeek: true,
  useNearestWeekday: true,
  useNthWeekdayOfMonth: true,
};
