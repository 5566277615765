import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import s from './styles.module.scss';

export const CaptionSkeleton = () => (
  <div className={s.caption}>
    <Skeleton height={13} width="8px" />
    <Skeleton height={13} width="70px" />
  </div>
);
