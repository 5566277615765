import { MouseEvent, MouseEventHandler, type ReactElement } from 'react';
import { components, OptionProps } from 'react-select';
import cx from 'classnames';
import { IconButton } from '../../../button';
import { TOption } from '../types';
import s from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/naming-convention
type TOptionProps<Option, isMulti extends boolean> = OptionProps<Option, isMulti>;

const optionOnClick = (
  e: MouseEvent<HTMLDivElement>,
  isDisabled: boolean,
  onClick: MouseEventHandler<HTMLDivElement> | undefined,
) => {
  if (!isDisabled && onClick) onClick(e);
  return '';
};

const renderAction = (action) => (
  <IconButton
    buttonClassName={cx(s.actionIcon, action.className)}
    className={cx(s.actionTooltip, action.tooltipClass)}
    {...action}
  />
);

export const Option = <T extends TOption, K extends boolean>({
  children,
  data,
  innerProps,
  isDisabled = false,
  ...props
}: TOptionProps<T, K>): ReactElement => {
  return (
    <components.Option
      {...props}
      data={data}
      isDisabled={data.disabled || isDisabled}
      innerProps={{
        ...innerProps,
        onClick: (e) => optionOnClick(e, data.disabled as boolean, innerProps.onClick),
      }}
    >
      {data?.action ? (
        <div className={s.action}>
          <div>{children}</div>

          {renderAction(data.action)}
        </div>
      ) : (
        <div>{children}</div>
      )}
    </components.Option>
  );
};
