import cx from 'classnames';
import s from './styles.module.scss';

interface IFormControl {
  className?: string;
  labelText?: string;
  footerText?: string;
  component: any;
  labelButton?: JSX.Element;
  disabled?: boolean;
  id: string;
  // TODO: change
  error?: string;
  [key: string]: any;
}
const FormControl = ({
  labelText,
  labelButton,
  footerText,
  className,
  component,
  error,
  id,
  disabled,
  ...props
}: IFormControl): JSX.Element => {
  const Input = component;

  return (
    <div className={cx(s.formControl, className, (error || footerText) && s.formControlPadding)}>
      {labelText && (
        <div className={s.formControlLabelWrapper}>
          <label htmlFor={id} className={cx(s.formControlLabel, disabled && s.formControlLabelDisabled)}>
            {labelText}
          </label>
          {labelButton}
        </div>
      )}

      <Input disabled={disabled} {...props} error={error} className={cx(s.formControlInput)} />

      <div className={cx(s.formControlErrorLabel, !error && s.formControlErrorLabelHidden)}>{error}</div>

      {footerText && <div className={s.formControlFooterLabel}>{footerText}</div>}
    </div>
  );
};

export default FormControl;
