import { ReactElement, useMemo, useState } from 'react';
import { IBreadcrumbsRoutesMapProps, BreadcrumbsContext, IBreadcrumbsItemRouteProps } from './context';

export interface BreadcrumbsProviderProps {
  children: ReactElement;
}

export interface BreadcrumbsProviderState {
  routes: IBreadcrumbsRoutesMapProps;
}

export const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [routes, setRoutes] = useState<IBreadcrumbsRoutesMapProps>({
    _default: [],
  });
  const [updated, setUpdated] = useState(false);

  const register = (route: IBreadcrumbsItemRouteProps, id: string): void => {
    setRoutes(() => Object.assign(routes, { [id]: [...routes[id], route] }));
  };

  const unregister = (route: IBreadcrumbsItemRouteProps, id: string): void => {
    setRoutes(() => Object.assign(routes, { [id]: routes[id].filter((rt) => rt.path !== route.path) }));
  };

  const update = (route: IBreadcrumbsItemRouteProps, id: string): void => {
    setRoutes(() => Object.assign(routes, { [id]: routes[id].map((rt) => (rt.path === route.path ? route : rt)) }));
    setUpdated(!updated);
  };

  const valueProvider = useMemo(() => {
    return { routes, register, unregister, update };
  }, [routes, register, unregister, update]);

  return <BreadcrumbsContext.Provider value={valueProvider}>{children}</BreadcrumbsContext.Provider>;
};
