import { memo } from 'react';
import cx from 'classnames';
import { memory } from 'app/constants/data';
import { handleIntegerInput } from 'app/utils/helpers';
import { storage } from 'app/constants/participants';
import { StorageDto } from 'data';
import { SelectInput, TextInput, Text } from 'ui';
import { StorageBackupSize } from './backup-size';
import { Field } from '../ui/form/field';
import s from './styles.module.scss';

type StorageProps = {
  type: StorageDto.type;
  className?: string;
  wrapperClassName?: string;
};

const StorageComponent = ({ type, className, wrapperClassName }: StorageProps) => (
  <div className={cx(s.wrapper, wrapperClassName)}>
    <Text component="p" weight="semibold" size="m" className={s.containerFormSubtitle}>
      Storage settings
    </Text>
    <div className={cx(s.containerBody, className)}>
      <Field
        className={s.containerFormField}
        component={SelectInput}
        id="storage.type"
        name="storage.type"
        labelText="Storage"
        options={storage}
        placeholder="Select storage..."
        onChangeAdapter={(v) => v?.value}
        valueAdapter={(v) => storage?.find((el) => el.value === v) || ''}
      />

      <div />

      {type && type !== StorageDto.type.MEMORY && (
        <>
          <Field
            className={s.containerFormField}
            component={TextInput}
            id="storage.user"
            name="storage.user"
            labelText="User"
          />
          <Field
            className={s.containerFormField}
            component={TextInput}
            id="storage.password"
            name="storage.password"
            labelText="Password"
            type="password"
          />
          {type === StorageDto.type.EXTERNAL && (
            <>
              <Field
                className={s.containerFormField}
                component={TextInput}
                id="storage.hostname"
                name="storage.hostname"
                labelText="Hostname"
              />
              <Field
                className={s.containerFormField}
                component={TextInput}
                id="storage.port"
                name="storage.port"
                labelText="Port"
              />
            </>
          )}
          {type === StorageDto.type.POSTGRES && (
            <div className={cx(s.containerFormField, s.fieldGroup)}>
              <Field
                className={s.fieldGroupMain}
                component={TextInput}
                id="storage.size.value"
                name="storage.size.value"
                labelText="Storage size"
                onKeyPress={handleIntegerInput}
              />
              <Field
                className={s.fieldGroupSelect}
                options={memory}
                component={SelectInput}
                id="storage.size.unit"
                name="storage.size.unit"
                onChangeAdapter={(v) => v?.value}
                valueAdapter={(v) => {
                  return memory?.find((el) => el.value === v) || '';
                }}
                maxMenuHeight="200px"
                isMenuAbove
              />
            </div>
          )}

          <StorageBackupSize />
        </>
      )}
    </div>
  </div>
);

export const Storage = memo(StorageComponent);
