import { DarDto } from 'data';

export const generateVersion = (str) => {
  const regexDarFileVersion = /\d{1,2}\.\d{1,2}\.\d{1,3}/;
  const v = regexDarFileVersion.exec(str);

  if (v !== null) {
    return v[0];
  }
  return undefined;
};

export const preparingFileListData = (fileList) =>
  (fileList?.map((el) => {
    return {
      name: el.label,
      version: generateVersion(el.label) || '',
    };
  }) || []) as DarDto[];

export const generateSizeLabel = (size) => `${parseFloat((size * 0.000001).toString()).toFixed(2)} mb`;
