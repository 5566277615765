/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnvVar } from './EnvVar';
import type { ResourcesDto } from './ResourcesDto';
// for FE types validation
import { StorageDto } from './StorageDto';

export type RemoteDomainDto = {
  adminAddress: string;
  adminPort: string;
  envVars?: Array<EnvVar>;
  image: string;
  name: string;
  phase?: RemoteDomainDto.phase;
  publicAddress: string;
  publicPort: string;
  resources?: ResourcesDto;
  // for FE types validation
  storage?: StorageDto;
};

export namespace RemoteDomainDto {
  export enum phase {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
  }
}
