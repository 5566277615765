import { CSSProperties } from 'react';
import { StylesConfig } from 'react-select';
import type { TOption } from './types';

export const dropdownStyles = ({
  error,
  isMulti = false,
  // isDisabled = false,
  isMenuAbove = false,
}): StylesConfig<TOption, boolean> => ({
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
    fontSize: 14,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#96A1A8',
  }),
  control: (base, { selectProps, isDisabled }) => {
    const generateBgColor = () => (isDisabled ? '#F6F6F6' : '#FFFFFF');
    const generateBorderColor = () => {
      if (error) return '#D63831';
      // if (isDisabled) return `#88a5bf`;
      // return isFocused ? '#88A5BF' : '#88a5bf';
      return '#88a5bf';
    };
    const customControlStyles = (): CSSProperties => ({
      color: 'white',
      cursor: selectProps.menuIsOpen ? 'text' : 'pointer',
      backgroundColor: generateBgColor(),
      borderStyle: 'solid',
      borderColor: generateBorderColor(),
      boxSizing: 'border-box',
      boxShadow: selectProps.menuIsOpen ? '0 0 1px 1px #244E74' : 'none',
      borderRadius: 0,
      minHeight: 40,
      margin: 0,
      opacity: isDisabled ? 0.4 : 1,
      pointerEvents: isDisabled ? 'none' : 'all',
    });
    return {
      ...base,
      ...customControlStyles(),
      '&:hover': {
        borderColor: '#244E74',
      },
    };
  },
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  menu: (base) => {
    const position = {} as {
      top?: string;
      bottom?: string;
      left?: string;
      right?: string;
    };

    if (isMenuAbove) {
      position.top = 'auto';
      position.bottom = 'calc(100% - 5px)';
    }

    return {
      ...base,
      marginTop: 2,
      borderRadius: 2,
      textAlign: 'left',
      border: '1px solid #88A5BF',
      ...position,
    };
  },
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 2,
  }),
  option: (base, { isSelected, data, isFocused, isDisabled }) => {
    let back = '#FFF';
    if (isSelected && !isMulti) back = '#A0C3D9';
    if (isDisabled) back = '#F6F6F6';
    const color = data?.isLink ? '#417fa6' : '#0D1D27';

    const customOptionStyles = (): CSSProperties => ({
      backgroundColor: isFocused ? '#D5EBF4' : back,
      padding: 12,
      color: isDisabled ? '#96A1A8' : color,
      display: 'flex',
      alignItems: 'center',
      // '&:hover': {
      //   backgroundColor: '#D5EBF4',
      // },
      wordBreak: 'break-all',
      cursor: data?.isLink || isFocused ? 'pointer' : 'default',
    });

    return {
      ...base,
      ...customOptionStyles(),
    };
  },
  noOptionsMessage: (base) => ({
    ...base,
    padding: 12,
    color: '#0D1D27',
    textAlign: 'left',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#D5EBF4',
    color: '#0D1D27',
    padding: '4px 8px',
    boxSizing: 'border-box',
    borderRadius: 4,
  }),
  multiValueRemove: (base) => ({
    ...base,
    '&:hover': {
      backgroundColor: '#D5EBF4',
      cursor: 'pointer',
    },
  }),
  clearIndicator: (base) => {
    const customClearIndicator = (): CSSProperties => ({ paddingRight: 0 });
    return {
      ...base,
      ...customClearIndicator(),
    };
  },
  indicatorsContainer: (base) => ({
    ...base,
    alignItems: 'stretch',
    '& > div': {
      paddingTop: 12,
    },
  }),
  group: (base) => ({
    ...base,
    paddingBottom: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    color: '#0D1D27',
    lineHeight: '20px',
    '& + div > div': {
      paddingLeft: '20px',
    },
  }),
  dropdownIndicator: (base) => ({ ...base }),
});
