import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';

// import PrivateRoute from 'app/utils/private-route';
// const AuthorizedUserRoute = withAuth({
//   redirectTo: loginPath,
//   role: Roles.ROLE_USER,
//   preloader: <Loader.FullPage />,
// })(Layout);

const Layout = loadable(() => import('app/pages/layout'));

const App = () => {
  return (
    <Routes>
      <Route path="/*" element={<Layout />} />
    </Routes>
  );
};

export default App;
