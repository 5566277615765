import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { PageSubCaption, Table } from 'app/components/core/ui';
import { NoEntityData } from 'app/components/core/empty-state';
import { dayTimeFormatFromUnix } from 'app/utils';
import { type RestoreHistoryDto, errorTransformer } from 'data';
import { Status, TableSkeleton, Text } from 'ui';
import { ErrorWireframe } from '../error-wireframe';
import s from './styles.module.scss';

type RestoreHistoryProps = {
  restoreHistory: RestoreHistoryDto[] | undefined;
  isHistoryLoading: boolean;
  historyLoadingError: unknown;
};

const mapStatusToColor = {
  succeeded: Status.mode.success,
  running: Status.mode.success,
  pending: Status.mode.info,
  unknown: Status.mode.info,
  failed: Status.mode.error,
};

export const RestoreHistory = ({ restoreHistory, isHistoryLoading, historyLoadingError }: RestoreHistoryProps) => {
  const cols = useMemo<ColumnDef<RestoreHistoryDto>[]>(
    () => [
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ getValue }) => {
          const status = getValue() as string;

          return <Status mode={mapStatusToColor[status.toLowerCase()]}>{status}</Status>;
        },
      },
      {
        header: 'File',
        accessorKey: 'filename',
      },
      {
        header: 'Log',
        accessorKey: 'log',
      },
      {
        header: 'Timestamp',
        accessorKey: 'timestamp',
        cell: ({ getValue }) => dayTimeFormatFromUnix(getValue() as string),
      },
    ],
    [],
  );
  const tableContent =
    restoreHistory && restoreHistory.length > 0 ? (
      <Table data={restoreHistory} columns={cols} isPaginated />
    ) : (
      <NoEntityData entityName="restore schedule" />
    );

  if (historyLoadingError) {
    return (
      <div className={s.history}>
        <Text size="xl" weight="regular" className={s.historyHeader}>
          Restore history
        </Text>

        <ErrorWireframe message={errorTransformer(historyLoadingError)} />
      </div>
    );
  }

  return (
    <div className={s.history}>
      <div className={s.containerHeader}>
        <Text size="xl" weight="regular" className={s.historyHeader}>
          Restore history
        </Text>

        <PageSubCaption isDataLoaded={!isHistoryLoading} text="item" data={restoreHistory} />
      </div>

      {isHistoryLoading ? <TableSkeleton /> : tableContent}
    </div>
  );
};
