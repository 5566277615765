import cx from 'classnames';
import s from './styles.module.scss';

import copy from './icons/copy.svg';
import search from './icons/search.svg';
import arrowDown from './icons/arrowDown.svg';
import arrowUp from './icons/arrowUp.svg';
import arrowLeft from './icons/arrowLeft.svg';
import close from './icons/close.svg';
import info from './icons/info.svg';
import dashboard from './icons/dashboard.svg';
import peer from './icons/peer.svg';
import channel from './icons/channel.svg';
import add from './icons/add.svg';
import edit from './icons/edit.svg';
import remove from './icons/remove.svg';
import link from './icons/link.svg';
import certificate from './icons/certificate.svg';
import eyeClose from './icons/eyeClose.svg';
import eyeOpen from './icons/eyeOpen.svg';
import addChannel from './icons/addChannel.svg';
import addOrganization from './icons/addOrganization.svg';
import anchorPeer from './icons/anchorPeer.svg';
import error from './icons/error.svg';
import errorBg from './icons/error_bg.svg';
import pending from './icons/pending.svg';
import running from './icons/running.svg';
import exportIcon from './icons/export.svg';
import importIcon from './icons/import.svg';
import join from './icons/join.svg';
import user from './icons/user.svg';
import addUser from './icons/addUser.svg';
import processing from './icons/processing.svg';
import addChaincode from './icons/addChaincode.svg';
import organization from './icons/organization.svg';
import updateAnchor from './icons/updateAnchor.svg';
import chaincode from './icons/chaincode.svg';
import addDepartment from './icons/addDepartment.svg';
import department from './icons/department.svg';
import partners from './icons/partners.svg';
import boldArrows from './icons/boldArrows.svg';
import boldArrowUp from './icons/boldArrowUp.svg';
import boldArrowDown from './icons/boldArrowDown.svg';
import approved from './icons/approved.svg';
import upgradeChaincode from './icons/upgradeChaincode.svg';
import orderer from './icons/orderer.svg';
import signout from './icons/signout.svg';
import retry from './icons/retry.svg';
import addConsenter from './icons/addConsenter.svg';
import notification from './icons/notification.svg';
import block from './icons/block.svg';
import complete from './icons/complete.svg';
import vote from './icons/vote.svg';
import settings from './icons/settings.svg';
import group from './icons/group.svg';
import ram from './icons/ram.svg';
import node from './icons/node.svg';
import hdd from './icons/hdd.svg';
import cpu from './icons/cpu.svg';
import cloudConsole from './icons/cloudConsole.svg';
import cas from './icons/cas.svg';
import editFile from './icons/edit-file.svg';
import switchIcon from './icons/switch.svg';
import ca from './icons/ca.svg';
import errorRound from './icons/errorRound.svg';
import success from './icons/success.svg';
import successFulfilled from './icons/successFulfilled.svg';
import successCheck from './icons/successCheck.svg';
import question from './icons/question.svg';
import minus from './icons/minus.svg';
import plus from './icons/plus.svg';
import actions from './icons/actions.svg';
import enroll from './icons/enroll.svg';
import password from './icons/password.svg';
import reenroll from './icons/reenroll.svg';
import revoke from './icons/revoke.svg';
import sync from './icons/sync.svg';
import peerPlus from './icons/peerPlus.svg';

const icons = {
  cas,
  editFile,
  boldArrows,
  boldArrowUp,
  boldArrowDown,
  addDepartment,
  partners,
  department,
  copy,
  search,
  arrowDown,
  arrowUp,
  close,
  info,
  dashboard,
  peer,
  channel,
  arrowLeft,
  add,
  edit,
  remove,
  link,
  certificate,
  eyeClose,
  eyeOpen,
  addChannel,
  addOrganization,
  anchorPeer,
  error,
  errorBg,
  pending,
  running,
  exportIcon,
  importIcon,
  join,
  user,
  addUser,
  processing,
  addChaincode,
  organization,
  updateAnchor,
  chaincode,
  approved,
  upgradeChaincode,
  orderer,
  signout,
  retry,
  addConsenter,
  notification,
  block,
  complete,
  vote,
  settings,
  group,
  ram,
  node,
  hdd,
  cpu,
  cloudConsole,
  switchIcon,
  ca,
  errorRound,
  success,
  successFulfilled,
  successCheck,
  question,
  minus,
  plus,
  actions,
  enroll,
  password,
  reenroll,
  revoke,
  sync,
  peerPlus,
};

export type TIcon = typeof icons | keyof typeof icons;

export interface IIcon extends React.HTMLAttributes<SVGAElement> {
  icon: TIcon;
  className?: string;
  size?: 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
}

export const Icon = ({ icon, className, size = 'M', ...props }: IIcon): JSX.Element => {
  const IconComponent = typeof icon === 'string' ? icons[icon] : icon;

  return <IconComponent className={cx(s.icon, s[`size__${size}`], className)} {...props} />;
};

Icon.icons = icons;
