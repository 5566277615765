// eslint-disable-next-line import/no-extraneous-dependencies
import { format, intervalToDuration, addMinutes } from 'date-fns';

export const UTC = (date) => addMinutes(date, date.getTimezoneOffset());

export const fromISO = (date) => format(UTC(new Date(date)), 'dd.MM.yyyy HH:mm');

export const defaultFormat = (date) => format(UTC(date), 'dd.MM.yyyy HH:mm');

export const logsScannedFormat = (date) => format(UTC(date), 'MM/dd/yyyy HH:mm aaaa');
export const logsTimeStampFormat = (date) => format(UTC(date), 'yyyy/MM/dd HH:mm:ss');

export const timeFormat = (date) => format(UTC(date), 'HH:mm');

export const dayFormat = (date) => format(UTC(date), 'dd.MM.yyyy');

export const dayTimeFormat = (date) => format(UTC(date), 'dd/MM/yyyy HH:mm:ss');

export const dayTimeFormatFromUnix = (date) => dayTimeFormat(new Date(+date * 1000));

export const dateValidation = (obj) =>
  `${obj.years ? `${obj.years}y` : ''}${obj.months ? `${obj.months}mo` : ''}${obj.days ? `${obj.days}d` : ''}${
    obj.hours ? `${obj.hours}h` : ''
  }${obj.minutes ? `${obj.minutes}m` : ''}${obj.seconds ? `${obj.seconds}s` : ''}`;

export const duration = (end, start) => intervalToDuration({ end, start });
