import { FC, useEffect } from 'react';
import cx from 'classnames';
import s from './styles.module.scss';

interface IPreloader {
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  loaderImageClassName?: string;
  color?: string;
}

export const Loader = ({ color = '#244e74', loaderImageClassName }: IPreloader): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className={cx(s.preloaderImage, loaderImageClassName)}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke={color}
      strokeWidth="2"
      r="20"
      strokeDasharray="70.68583470577033 25.561944901923447"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

const Small = ({ className, color, loaderImageClassName }: IPreloader): JSX.Element => (
  <div className={cx(s.preloaderSmall, className)}>
    <Loader loaderImageClassName={loaderImageClassName} color={color} />
  </div>
);

Loader.Small = Small;

const FullPage: FC = ({ color, loaderImageClassName }: IPreloader): JSX.Element => {
  const handleClick = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });

  return (
    <div className={s.preloader}>
      <Loader loaderImageClassName={loaderImageClassName} color={color} />
    </div>
  );
};

Loader.FullPage = FullPage;

export * from './skeleton';
