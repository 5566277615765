import { Text, Icon, Link, type TIcon } from 'ui';
import cx from 'classnames';

import s from './styles.module.scss';

interface Props {
  message?: string | string[];
  to?: string;
  linkText?: string;
  className?: string;
  reload?: boolean;
  icon?: TIcon;
}

export const ErrorWireframe = ({ message, to, linkText, className, reload, icon }: Props) => {
  const reloadPage = () => {
    window.location.reload();
  };
  if (Array.isArray(message)) {
    return (
      <>
        {message.map((msg) => (
          <div key={msg} className={cx(s.container, s.containerError, className)}>
            <Icon icon={Icon.icons.errorRound} />
            <Text className={s.containerMessage} color="error">
              {msg}
            </Text>
          </div>
        ))}
      </>
    );
  }
  return (
    <div className={cx(s.container, s.containerError, className)}>
      <Icon icon={icon || Icon.icons.errorRound} />
      <Text className={s.containerMessage} color="error">
        {message}
      </Text>
      {to && linkText && (
        <Link to={to} className={s.containerMessageLink}>
          {linkText}
        </Link>
      )}
      {reload && (
        <button type="button" className={s.containerMessageLink} onClick={reloadPage}>
          Reload page.
        </button>
      )}
    </div>
  );
};
