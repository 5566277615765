import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const useDisableBodyScroll = (ref: React.RefObject<HTMLElement>, condition?: boolean): void => {
  useEffect(() => {
    if (ref.current) {
      if (condition || typeof condition === 'undefined') {
        disableBodyScroll(ref.current);
      } else {
        enableBodyScroll(ref.current);
      }
    }

    return clearAllBodyScrollLocks;
  }, [condition]);
};
