/* eslint-disable no-param-reassign */
import { ReactNode, createElement } from 'react';
import cx from 'classnames';
import weights from 'ui/styles/_weights.module.scss';
import sizes from 'ui/styles/_sizes.module.scss';
import colors from 'ui/styles/_colors.module.scss';
import { TColors, TextSizes, TWeights } from 'ui/styles/types';

import s from './styles.module.scss';

interface IText {
  color?: TColors;
  size?: TextSizes;
  weight?: TWeights;
  className?: string;
  children: ReactNode;
  component?: keyof React.ReactHTML;
  style?: React.CSSProperties;
  isBreak?: boolean;
  [key: string]: any;
}

export type TypeConstructor<T extends string> = Record<T, T> | Record<string, never>;

const color = Object.entries(colors).reduce((obj: TypeConstructor<TColors>, [colorName]) => {
  obj[colorName] = s[`textColor__${colorName}`];
  return obj;
}, {});

const size = Object.entries(sizes).reduce((obj: TypeConstructor<TextSizes>, [sizeName]) => {
  obj[sizeName] = s[`textSize__${sizeName}`];
  return obj;
}, {});

const weight = Object.entries(weights).reduce((obj: TypeConstructor<TWeights>, [weightName]) => {
  obj[weightName] = s[`textWeight__${weightName}`];
  return obj;
}, {});

const Text = ({
  color: colorProp,
  size: sizeProp,
  weight: weightProp,
  className,
  children,
  style,
  isBreak,
  component = 'span',
  ...props
}: IText): JSX.Element => {
  return createElement(
    component,
    {
      className: cx(
        component === 'span' && s.text,
        isBreak && s.textBreak,
        // for compatibility with existing codebase next 3 props is not deleted
        colorProp,
        sizeProp,
        weightProp,
        colorProp && s[`textColor__${colorProp}`],
        sizeProp && s[`textSize__${sizeProp}`],
        weightProp && s[`textWeight__${weightProp}`],
        className,
      ),
      style,
      ...props,
    },
    children,
  );
};

Text.color = color;
Text.size = size;
Text.weight = weight;

export { Text };
