import { createContext } from 'react';
import { StepId, ServerErrors } from './types';

export type TStepperContext = {
  serverErrors: ServerErrors;
  setServerErrors: (errors: ServerErrors) => void;
  hasStepError: (id: StepId) => boolean;
};

export const StepperContext = createContext<TStepperContext>({
  serverErrors: {} as ServerErrors,
  setServerErrors: () => {},
  hasStepError: () => false,
});
