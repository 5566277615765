import { type IInfoCardButtons } from 'app/components/core';
import { StorageDto } from 'data';

export const image = 'digitalasset/canton-open-source:2.7.9';
export const adminPort = '5019';
export const ledgerPort = '5011';
export const storageType = StorageDto.type.MEMORY;

export const storage = [
  {
    value: StorageDto.type.MEMORY,
    label: 'Memory',
  },
  {
    value: StorageDto.type.POSTGRES,
    label: 'PostgreSQL',
  },
  {
    value: StorageDto.type.EXTERNAL,
    label: 'External',
  },
];

export const skeletonButtonsDetails: IInfoCardButtons[] = [
  { title: 'Connect to domain', variant: 'primary', disabled: true },
  { title: `Edit`, variant: 'primary', disabled: true },
  { title: 'Remove participant', variant: 'secondary', disabled: true },
];
