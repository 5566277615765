import { memo } from 'react';
import { Text, CaptionSkeleton } from 'ui';

interface IFormCaption {
  isDataLoaded: boolean;
  data: Array<any> | undefined;
  text: string;
  isTotalView?: boolean;
}

// eslint-disable-next-line react/display-name
export const PageSubCaption = memo(({ isDataLoaded, data, text, isTotalView }: IFormCaption): JSX.Element => {
  let suffix;
  let content;
  if (isDataLoaded && data) {
    suffix = data.length === 1 ? '' : 's';

    content = isTotalView ? `Total items: ${data.length}` : `${data.length} ${text}${suffix}`;
  } else {
    content = <CaptionSkeleton />;
  }
  return (
    <Text size={Text.size.s} weight={Text.weight.regular} color={Text.color.fontPrimaryDisabled}>
      {content}
    </Text>
  );
});

PageSubCaption.displayName = 'PageSubCaption';
