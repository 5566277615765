/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

export interface IBreadcrumbsItemRouteProps {
  link?: string;
  path?: string;
  disabled?: boolean;
  component: React.ReactElement;
  itemProps?: object;
}

export interface IBreadcrumbsRoutesMapProps {
  [key: string]: IBreadcrumbsItemRouteProps[];
}

export interface IBreadcrumbsContextProps {
  routes: IBreadcrumbsRoutesMapProps;
  register(route: IBreadcrumbsItemRouteProps, id: string): void;
  unregister(route: IBreadcrumbsItemRouteProps, id: string): void;
  update(route: IBreadcrumbsItemRouteProps, id: string): void;
}

export const BreadcrumbsContext = createContext<IBreadcrumbsContextProps>({
  routes: {},
  register: () => {},
  unregister: () => {},
  update: () => {},
});
