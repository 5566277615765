import type { ReactElement } from 'react';
import Select, { Props } from 'react-select';
import cx from 'classnames';
import CustomDropDownIndicator from './dropdown';
import { dropdownStyles } from './custom-styles';
import type { TOption } from './types';
import { Option } from './option';
import s from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/naming-convention
type SelectProps<Option, isMulti extends boolean = false> = Props<Option, isMulti> & {
  error?: any;
  isMenuAbove?: boolean;
  label?: string;
  wrapperClassName?: string;
};

export const SelectInput = <T extends TOption>({
  isMulti,
  label,
  wrapperClassName,
  ...props
}: SelectProps<T | TOption | any>): ReactElement => {
  return (
    <div className={cx(s.select, wrapperClassName)}>
      {label && (
        <div className={s.selectLabelWrapper}>
          {/* eslint-disable-next-line */}
          <label className={s.selectLabel}>{label}</label>
        </div>
      )}
      <Select
        defaultMenuIsOpen={false} // suitable for testing
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        {...props}
        components={{
          DropdownIndicator: CustomDropDownIndicator,
          Option: ({ ...selectProps }) => <Option {...selectProps} />,
        }}
        styles={dropdownStyles(props as any)}
      />
    </div>
  );
};
