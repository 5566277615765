import { MemoryEnum } from 'data';

export const memoryDefault = '1';
export const memoryLimit = '2';
export const cpuLimit = '2';
export const cpuRequested = '1';
export const sizeDefault = '1';
export const backupSizeDefault = '1';
export const maxDurationInSecDefault = 1;
export const retentionInSecDefault = 1;

export const defaultJvmOptions = '-Xms1152M -Xmx1152M -Dscala.concurrent.context.minThreads=4';
export const minTopupInterval = '1m';
export const targetThroughput = 33330;

export type UnitAdapter = {
  value: string;
  unit: MemoryEnum | keyof typeof MemoryEnum;
};

export const memory = Object.values(MemoryEnum).map((el) => ({ value: el, label: el }));
