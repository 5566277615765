/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';

const BASE_URL = window._env_?.CONFIG_BASEURL || import.meta.env.VITE_BASE_URL;
const UI_BUILD = window._env_?.UI_BUILD || import.meta.env.VITE_UI_BUILD;
const POLLING_INTERVAL = window._env_?.POLLING_INTERVAL || import.meta.env.VITE_POLLING_INTERVAL;
// const BASE_URL = import.meta.env.VITE_BASE_URL;
const requestPath = (targetQuery: string) => `${BASE_URL}/api/${targetQuery}`;

export const useAppConfig = () => {
  const result = useMemo(() => {
    return {
      pollingInterval: Number(POLLING_INTERVAL),
      baseUrl: BASE_URL as string,
      version: UI_BUILD as string,
      subscriptions: {
        logsUrl: requestPath('logs'),
        eventsUrl: requestPath('events'),
      },
    };
  }, []);

  return result;
};
