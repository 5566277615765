export type TMode = {
  type?: string;
  color: string;
  icon: string;
};

type ModeKeys = 'success' | 'processing' | 'info' | 'error' | 'warning' | 'unknown' | 'disabled';

export type TModes = Record<string, never> | Record<ModeKeys, TMode>;

export const modes: TModes = {
  success: {
    color: 'success',
    icon: 'successCheck',
  },
  processing: {
    color: 'accent',
    icon: 'pending',
  },
  info: {
    color: 'accent',
    icon: 'info',
  },
  error: {
    color: 'simpleWhite',
    icon: 'error',
  },
  warning: {
    color: 'simpleWhite',
    icon: 'info',
  },
  unknown: {
    color: 'simpleWhite',
    icon: 'question',
  },
  disabled: {
    color: 'simpleWhite',
    icon: 'info',
  },
};

export const statusServerConverter = (status: number | string | undefined) => {
  if (!status) return 'unknown';
  if (typeof status === 'number') {
    if (status < 200) return 'processing';
    if (status > 199 && status < 300) return 'success';
    if (status > 299 && status < 400) return 'processing';
    if (status > 399 && status < 500) return 'error';
    if (status > 499) return 'warning';
  }
  return status;
};
