import cx from 'classnames';
import { Icon } from '../../icon';
import { Text } from '../../text';
import { ButtonTooltip } from '../button';
import type { TMainContent, TIconButton } from '../types';
import s from './styles.module.scss';

export const MainContent = ({ icon, iconClassName, label, children, size = 'M' }: TMainContent): JSX.Element => (
  <>
    <Icon size={size} icon={icon} className={cx(s.buttonIcon, iconClassName)} />

    {!!(label || children) && (
      <Text size={Text.size.l} className={cx(s.buttonLabelText, s.buttonLabelText)}>
        {label || children}
      </Text>
    )}
  </>
);

export const IconButton = ({
  icon = Icon.icons.remove,
  text,
  iconClassName,
  buttonClassName,
  type = 'button',
  disabled = false,
  label,
  showHover = false,
  children,
  size,
  tooltipClass,
  ...props
}: TIconButton) => {
  const defaultClass = cx(
    s.button,
    !showHover && !label && !children && s.hideHover,
    label && s.buttonLabel,
    disabled && s.buttonDisabled,
    buttonClassName,
  );

  const content = (
    <button {...props} type={type} className={defaultClass} disabled={disabled}>
      <MainContent size={size} icon={icon} iconClassName={iconClassName} label={label}>
        {children}
      </MainContent>
    </button>
  );

  return (
    <ButtonTooltip text={text} tooltipClass={tooltipClass} {...props}>
      {content}
    </ButtonTooltip>
  );
};
