/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnvVar } from './EnvVar';
import type { ResourcesDto } from './ResourcesDto';
import type { StorageDto } from './StorageDto';
import { LogLevel } from '../../constants';

export type DomainDto = {
  adminAddress?: string;
  adminPort: string;
  bootstrap?: string;
  daemon?: boolean;
  /**
   * Whether the canton image is enterprise or not
   */
  enterprise?: boolean;
  envVars?: Array<EnvVar>;
  image: string;
  ingress?: boolean;
  logLevel?: LogLevel;
  name: string;
  phase?: DomainDto.phase;
  publicAddress?: string;
  publicPort: string;
  resources: ResourcesDto;
  storage: StorageDto;
  topology?: string;
};

export namespace DomainDto {
  export enum phase {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
  }
}
