import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cx from 'classnames';
import s from './styles.module.scss';

export const TableSkeleton = () => {
  return (
    <div className={cx(s.containerBody, s.tableSkeleton)}>
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
      <Skeleton height={35} inline />
    </div>
  );
};
