import { ReactNode } from 'react';
import cx from 'classnames';
import { Text } from '../text';
import s from './styles.module.scss';

type TNameTooltipPlacement = 'top' | 'right' | 'bottom' | 'left';

interface ITooltip {
  className?: string;
  textClassName?: string;
  children: ReactNode;
  text?: string;
  placement?: TNameTooltipPlacement;
  top?: string;
}

export const Tooltip = ({
  className,
  textClassName,
  children,
  text,
  placement = 'bottom',
  top,
}: ITooltip): JSX.Element => (
  <div className={cx(s.tooltip, className)}>
    {children}
    {!!text && (
      <div className={cx(s.tooltipText, textClassName, s[placement])} style={top ? { top } : {}}>
        <Text size={Text.size.m} color={Text.color.simpleWhite}>
          {text}
        </Text>
      </div>
    )}
  </div>
);
