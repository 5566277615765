import { LevelType } from 'data';

export type OptionLevelType = {
  value: LevelType;
  label: LevelType;
};

export const levels: OptionLevelType[] = Object.values(LevelType).map((l) => ({
  value: l,
  label: l,
}));
